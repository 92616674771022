import { render, staticRenderFns } from "./ConflictsAnalytics.vue?vue&type=template&id=3c521a60&scoped=true&"
import script from "./ConflictsAnalytics.vue?vue&type=script&lang=js&"
export * from "./ConflictsAnalytics.vue?vue&type=script&lang=js&"
import style0 from "./ConflictsAnalytics.vue?vue&type=style&index=0&id=3c521a60&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c521a60",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VCard,VCardTitle,VCheckbox,VCol,VHover,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle,VMenu,VRow,VSelect})
