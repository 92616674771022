<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1 justify-space-between">
          Analytics
          <v-hover v-slot="{ hover }">
            <v-menu
              open-on-hover
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  large
                  v-if="!hover"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <metrics-info></metrics-info>
            </v-menu>
          </v-hover>
        </v-card-title>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-row>
        <v-col cols="4">
          <v-select
            v-model="selectedMetrics"
            :items="metrics"
            return-object
            item-text="label"
            item-value="key"
            label="Metric"
            multiple
            chips
            small-chips
            :deletable-chips="!isOnlySelectedMetric"
          >
            <template v-slot:item="{ item }">
              <v-list-item @click="onMetricSelect(item)">
                <v-list-item-action>
                  <v-checkbox
                    v-model="selectedMetrics"
                    @change.self="onMetricSelect(item)"
                    :value="item"
                    :disabled="isOnlySelectedMetric"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col cols="4" v-if="hasSupervisorAccess">
          <v-select
            v-model="filter.owner"
            :items="accounts"
            return-object
            item-text="name"
            item-value="id"
            label="Account"
            multiple
            chips
            small-chips
            :deletable-chips="!isOnlySelectedAccount"
          ></v-select>
        </v-col>

        <v-col cols="2" class="custom-col">
          <datepicker
            v-model="filter.importDate"
            label="Date"
            range-mode
          ></datepicker>
        </v-col>

        <v-col cols="1">
          <div>
            <v-btn
              class="mt-3"
              color="primary"
              @click="filterData"
              :disabled="globalLoading || isEmptyFilter"
            >
              Filter
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <line-chart v-if="hasCollectionData" :chart-data="chartData"></line-chart>
    <empty-data v-else></empty-data>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { PageMenu } from "@/mixins/page-menu";
import EmptyData from "@/pages/supervisor/components/EmptyData.vue";
import { OWNERSHIP_CONFLICTS_ANALYTICS_METRICS } from "@/components/enums";
import Datepicker from "@/components/forms/inputs/Datepicker.vue";
import MetricsInfo from "@/pages/ownershipConflicts/analytics/components/MetricsInfo.vue";
import LineChart from "@/pages/supervisor/components/LineChart.vue";
import { deepCopy } from "@/components/helpers";

export default {
  name: "ConflictsAnalytics",

  components: {
    LineChart,
    Datepicker,
    EmptyData,
    MetricsInfo
  },

  mixins: [PageMenu],

  data: () => ({
    collectionData: null,
    accounts: [],
    selectedMetrics: [],
    filter: {
      importDate: null,
      owner: []
    },
    persistentStateKey: "ownership-conflicts-analytics"
  }),

  computed: {
    ...mapGetters(["globalLoading", "isSupervisor", "userCurrentAccount"]),

    ...mapState({
      persistentData: state => state.persistent.persistentData
    }),

    metrics() {
      return Object.entries(OWNERSHIP_CONFLICTS_ANALYTICS_METRICS).map(
        ([key, label]) => ({
          key,
          label
        })
      );
    },

    hasSupervisorAccess() {
      return this.isSupervisor;
    },

    hasCollectionData() {
      return this.collectionData ? this.collectionData.length > 0 : false;
    },

    hasSelectedMetrics() {
      return this.selectedMetrics.length > 0;
    },

    isOnlySelectedMetric() {
      return this.selectedMetrics.length === 1;
    },

    isOnlySelectedAccount() {
      return this.filter.owner.length === 1;
    },

    isEmptyFilter() {
      return Object.values(this.filter).every(
        value => value === null || !value.length
      );
    },

    defaultMetrics() {
      const metricKey = "total_conflicting_recordings";

      return [
        {
          key: metricKey,
          label: OWNERSHIP_CONFLICTS_ANALYTICS_METRICS[metricKey]
        }
      ];
    },

    defaultAccount() {
      return {
        id: this.userCurrentAccount.id,
        name: this.userCurrentAccount.name
      };
    },

    chartData() {
      if (!this.hasCollectionData || !this.hasSelectedMetrics) {
        return {
          labels: [],
          datasets: []
        };
      }

      const labels = [];
      const datasets = [];

      const datasetMap = new Map();

      this.collectionData.forEach(entry => {
        const date = entry.import_date;
        labels.push(date);

        entry.metrics.forEach(metric => {
          this.selectedMetrics.forEach(({ key, label }) => {
            const ownerMetricName = `${label} – ${metric.owner.name}`;

            if (!datasetMap.has(ownerMetricName)) {
              const color = this.getRandomColor();

              datasetMap.set(ownerMetricName, {
                label: ownerMetricName,
                data: [],
                backgroundColor: color,
                borderColor: color,
                borderWidth: 2
              });
            }

            const dataset = datasetMap.get(ownerMetricName);
            dataset.data.push(metric.data[key] || 0);
          });
        });
      });

      datasetMap.forEach(dataset => datasets.push(dataset));

      return {
        labels: labels.sort(),
        datasets
      };
    }
  },

  methods: {
    ...mapActions([
      "getAssetsConflictsAnalytics",
      "getAccounts",
      "setGlobalLoading",
      "setPersistentData"
    ]),

    fetchData() {
      this.collectionData = null;
      this.setGlobalLoading(true);

      this.getAssetsConflictsAnalytics(this.filter)
        .then(({ data }) => {
          this.collectionData = data;
        })
        .finally(() => {
          this.setGlobalLoading(false);
        });
    },

    filterData() {
      this.storePersistentState();
      this.fetchData();
    },

    fetchAccountsData() {
      const accountsParams = {
        size: 50,
        sort_by: "name",
        sort_direction: "ASC"
      };

      this.getAccounts(accountsParams).then(({ data }) => {
        this.accounts = data
          .filter(acc => acc.settings.backup_assets_reports)
          .map(acc => ({
            id: acc.id,
            name: acc.name
          }));
      });
    },

    storePersistentState() {
      this.setPersistentData({
        key: this.persistentStateKey,
        value: {
          filter: this.filter,
          metrics: this.selectedMetrics
        }
      });
    },

    loadPersistentState() {
      const state = this.persistentData[this.persistentStateKey];

      if (state) {
        this.filter = deepCopy(state.filter);
        this.selectedMetrics = deepCopy(state.metrics);
      } else {
        this.filter.owner.push(this.defaultAccount);
        this.selectedMetrics = this.defaultMetrics;
      }
    },

    onMetricSelect(item) {
      const index = this.selectedMetrics.findIndex(i => i.key === item.key);

      if (index !== -1) {
        if (this.selectedMetrics.length > 1 || !this.isOnlySelectedMetric) {
          this.selectedMetrics.splice(index, 1);
        }
      } else {
        this.selectedMetrics.push(item);
      }
    },

    getRandomColor() {
      const hue = Math.floor(Math.random() * 360);
      const saturation = 70 + Math.random() * 20;
      const lightness = 50 + Math.random() * 10;

      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }
  },

  created() {
    this.loadPersistentState();
    this.fetchAccountsData();
    this.fetchData();
  },

  metaInfo: {
    title: "Analytics"
  }
};
</script>

<style scoped lang="sass">
.custom-col
  flex: 0 0 17.6666666667%
  max-width: 17.6666666667%
</style>
