import router from "@/router";
import { ROLES } from "@/components/enums";
import {
  LOGIN_PAGE_PATH,
  OAUTH_LOGIN_PAGE_PATH,
  LOGOUT_PAGE_PATH,
  SIGNUP_PAGE_PATH
} from "@/components/constants";
import { request } from "@/services/api";
import { VueApp } from "@/main.js";

const getDefaultState = () => {
  return {
    isAuthorized: false,
    currentAccountId: null,
    user: {}
  };
};

const state = getDefaultState();

// Creating a private method for module.
// This will set required cookies into the browser.
function _csrfFormProtection({ cookiePath }) {
  return request.get(cookiePath);
}

function _getFirstAccountId(userData) {
  let result = null;
  if (userData && userData.accounts && userData.accounts.length > 0) {
    result = userData.accounts[0].id;
  }

  return result;
}

function _processSuccessfulLogin(commit, userData) {
  commit("setAuthorized");
  commit("setUser", userData);

  const currentAccountId = _getFirstAccountId(userData);
  commit("setCurrentAccountId", currentAccountId);

  router.push("/");
}

function _processSuccessfulLogout(commit) {
  commit("unsetAuthorized");
  commit("unsetCurrentAccountId");
  commit("unsetUser");

  router.push(LOGIN_PAGE_PATH);
}

export default {
  name: "auth",
  state,
  actions: {
    initAction({ getters }, payload) {
      // We don't want to make unnecessary request to the backend in case it's
      // a public page, it may effect Page Load Time
      if (!payload.isPublicPage && getters.isAuthorized) {
        return this.dispatch("getUser").catch(() => {
          console.log("Wasn't able to receive user data");
        });
      } else {
        return Promise.resolve();
      }
    },

    processAccountIdInUrl({ getters }, payload) {
      const accountId = payload.accountId;

      console.log("Processing URL's accountId param: ", accountId);
      if (!accountId) {
        return;
      }

      if (getters.userCurrentAccountId !== accountId) {
        // Let's make sure that we have a fresh list of accounts
        const isAvailableAccount = getters.userAvailableAccountIds.includes(
          accountId
        );
        if (isAvailableAccount) {
          this.dispatch("switchCurrentAccountId", payload);
        } else {
          console.info("Cannot switch account to ", accountId);
        }
      }
    },

    getUser({ commit, rootState }, options = {}) {
      return request
        .get(`${rootState.apiPrefix}/users/me`, options)
        .then(responseBody => {
          const userData = responseBody?.data;

          commit("setUser", userData);
        });
    },

    updateCurrentUser({ commit, state, rootState }, userData) {
      return request
        .patch(`${rootState.apiPrefix}/users/me`, userData)
        .then(responseBody => {
          const userData = responseBody?.data,
            mergedUserData = { ...state.user, ...userData };

          commit("setUser", mergedUserData);
        });
    },

    login({ commit, rootState }, credentials) {
      return _csrfFormProtection(rootState).then(() => {
        return request
          .post(`${rootState.apiPrefix}${LOGIN_PAGE_PATH}`, credentials)
          .then(responseBody => {
            if (responseBody?.data) {
              const userData = responseBody?.data;

              _processSuccessfulLogin(commit, userData);
            } else {
              console.error("Login data is missing");
            }
          });
      });
    },

    logout({ commit, rootState }) {
      return request
        .post(`${rootState.apiPrefix}${LOGOUT_PAGE_PATH}`)
        .then(() => {
          _processSuccessfulLogout(commit);
        });
    },

    oauthLogin({ commit, rootState }, token) {
      return _csrfFormProtection(rootState).then(() => {
        return request
          .post(`${rootState.apiPrefix}${OAUTH_LOGIN_PAGE_PATH}`, {
            token: token
          })
          .then(responseBody => {
            if (responseBody?.data) {
              const userData = responseBody?.data;

              _processSuccessfulLogin(commit, userData);
            } else {
              console.error("Login data is missing");
            }
          });
      });
    },

    signup({ rootState }, credentials) {
      return _csrfFormProtection(rootState).then(() => {
        return request
          .post(`${rootState.apiPrefix}${SIGNUP_PAGE_PATH}`, credentials)
          .then(responseBody => {
            if (responseBody?.data) {
              router.push(LOGIN_PAGE_PATH);
            }
          });
      });
    },

    silentLogout({ commit }) {
      _processSuccessfulLogout(commit);

      return Promise.reject("Logout");
    },

    forcePageReload() {
      location.reload();

      return Promise.resolve({});
    },

    sendPasswordResetLink({ rootState }, credentials) {
      return request.post(
        `${rootState.apiPrefix}/forgot-password/email`,
        credentials
      );
    },

    resetPassword({ commit, rootState }, credentials) {
      return _csrfFormProtection(rootState).then(() => {
        return (
          request
            .post(`${rootState.apiPrefix}/forgot-password/reset`, credentials)
            .then(responseBody => {
              if (responseBody?.data) {
                const userData = responseBody?.data;
                _processSuccessfulLogin(commit, userData);
              } else {
                console.error("User's data is missing");
              }
            })
            // If the token is invalid or missing.
            .catch(({ response }) => {
              if ([422, 404].includes(response.status)) {
                router.push(LOGIN_PAGE_PATH);
              }
            })
        );
      });
    },

    switchCurrentAccountId({ commit }, payload) {
      if (typeof payload === "string") {
        payload = { accountId: payload };
      }

      const newAccountId = payload.accountId;
      let path = payload?.path;

      if (!path) {
        path = "/";
      }
      commit("setCurrentAccountId", newAccountId);

      if (router.currentRoute.path !== path) {
        console.log("New route path: ", path);
        router.push(path);
      } else {
        console.log("Avoided redundant navigation to current location: ", path);
      }
      console.log("Reload");
      location.reload();
    },

    checkInvitationCode({ commit, rootState }, invitationCode) {
      return _csrfFormProtection(rootState).then(() => {
        return request
          .post(`${rootState.apiPrefix}/onboarding/accept`, {
            code: invitationCode
          })
          .then(() => {
            commit("setInvitationCode", invitationCode);
            router.push("/onboarding");
          });
      });
    },

    onboardingSetPassword({ commit, rootState }, credentials) {
      return _csrfFormProtection(rootState).then(() => {
        return (
          request
            .post(`${rootState.apiPrefix}/onboarding/password`, credentials)
            .then(responseBody => {
              if (responseBody?.data) {
                const userData = responseBody?.data;
                _processSuccessfulLogin(commit, userData);
              } else {
                console.error("Login data is missing");
              }
            })
            // If the code (token) is invalid or missing.
            .catch(({ response }) => {
              if ([422, 404].includes(response.status)) {
                router.push(LOGIN_PAGE_PATH);
              }
            })
        );
      });
    },

    removeCurrentAccount({ commit, getters }) {
      return commit("removeAccount", getters.userCurrentAccountId);
    },

    setCurrentAccountSettings({ commit, getters }, settings) {
      let account = getters.userCurrentAccount;
      account.settings = settings;
      commit("replaceUserAccount", account);
    },

    forceSwitchingCurrentAccount({ commit, getters, dispatch }) {
      if (!getters.isConfirmSwitchAccountWindowActive) {
        commit("setConfirmSwitchAccountWindowState", true);
        dispatch("showSwitchingCurrentAccountModal");
      }

      return Promise.resolve({});
    },

    showSwitchingCurrentAccountModal({ commit, getters }) {
      let message =
        "You have lost access to this account. " +
        "Press 'Continue' to switch to another available account.";

      VueApp.$confirm(message, {
        buttonTrueText: "Continue",
        // looks like the library just checks if(value), so in this case the button won't be rendered :)
        buttonFalseText: "",
        persistent: true
      })
        .then(() => {
          let newAccountId = _getFirstAccountId(getters.userData);
          if (newAccountId) {
            this.dispatch("switchCurrentAccountId", newAccountId);
          } else {
            this.dispatch("silentLogout");
          }
        })
        .finally(() => commit("setConfirmSwitchAccountWindowState", false));
    },

    operationForbidden() {
      router.push({ name: "404" });
      return Promise.reject("Forbidden");
    }
  },
  getters: {
    isAuthorized(state) {
      return state.isAuthorized;
    },

    hasAdminAccess(state, getters) {
      const account = getters.userCurrentAccount;
      return !account
        ? false
        : [ROLES.admin, ROLES.supervisor].includes(account.role);
    },

    isSupervisor(state, getters) {
      const account = getters.userCurrentAccount;
      return !account ? false : account.role === ROLES.supervisor;
    },

    userData(state) {
      return state.user;
    },

    userCurrentAccount(state) {
      return state.user.accounts
        ? state.user.accounts.find(account => {
            return account.id === state.currentAccountId;
          })
        : null;
    },

    userCurrentAccountId(state) {
      return state.currentAccountId;
    },

    userAvailableAccounts(state) {
      return state.user.accounts ? state.user.accounts : [];
    },

    userAvailableAccountIds(state) {
      return state.user.accounts
        ? state.user.accounts.map(account => account.id)
        : [];
    },

    invitationCode(state) {
      return state.invitationCode;
    },

    isConfirmSwitchAccountWindowActive(state) {
      return state.isConfirmSwitchAccountWindowActive;
    }
  },

  mutations: {
    setConfirmSwitchAccountWindowState(state, value) {
      state.isConfirmSwitchAccountWindowActive = value;
    },
    setAuthorized(state) {
      state.isAuthorized = true;
    },

    unsetAuthorized(state) {
      state.isAuthorized = false;
    },

    setUser(state, data) {
      state.user = data;
    },

    unsetUser(state) {
      state.user = {};
    },

    unsetCurrentAccountId(state) {
      state.currentAccountId = null;
    },

    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    setCurrentAccountId(state, accountId) {
      state.currentAccountId = accountId;
    },

    replaceUserAccount(state, account) {
      let accounts = state.user.accounts;
      state.user.accounts = [
        ...accounts.filter(i => i.id !== account.id),
        account
      ];
    },

    setInvitationCode(state, invitationCode) {
      state.invitationCode = invitationCode;
    },

    removeAccount(state, accountId) {
      state.user.accounts.splice(
        state.user.accounts.findIndex(account => account.id === accountId),
        1
      );
    }
  }
};
